"use client";

import { useRef } from "react";
import { SimpleAvatar } from "@/components/ui/avatar";
import { Text } from "@/components/ui/Text";
import { motion, useScroll, useTransform } from "framer-motion";
import { MakeThemShine } from "./MakeThemShine";
export const KnowSomeone = () => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    scrollYProgress
  } = useScroll({
    target: ref,
    offset: ["start 50%", "end 50%"]
  });
  const scale = useTransform(scrollYProgress, [0, 1], [0.75, 1]);
  return <div className="flex min-h-dvh justify-center overflow-hidden bg-purple" ref={ref} data-sentry-component="KnowSomeone" data-sentry-source-file="KnowSomeone.tsx">
      <div className="container relative flex flex-col py-20 lg:gap-16">
        <Text.H3 className="z-10 max-w-xs text-yellow-2 animate-in slide-in-from-bottom-4 animate-ease-in" data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">
          Know someone <span className="italic">great?</span>
        </Text.H3>
        <div className="left-0 top-0 z-0 flex h-full w-full items-center justify-center max-lg:mt-8 lg:absolute lg:p-8">
          <picture data-sentry-element="picture" data-sentry-source-file="KnowSomeone.tsx">
            <motion.img alt="" src="/img/landing-page/placeholder.jpg" className="squircle-large mx-auto h-full w-auto overflow-hidden object-cover max-lg:max-h-[80vh] md:aspect-[6/7] lg:h-[80dvh]" style={{
            scale
          }} data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx" />
          </picture>
        </div>
        <div className="squircle top-96 z-10 w-full max-w-sm space-y-4 bg-purple-6 p-6 shadow-2xl max-lg:-translate-y-16 max-lg:translate-x-5 max-lg:scale-75 lg:absolute lg:ml-32 lg:p-8">
          <div className="flex items-center gap-2">
            <SimpleAvatar fallback="John" src="/img/landing-page/placeholder-2.jpg" avatarClassName="object-cover" data-sentry-element="SimpleAvatar" data-sentry-source-file="KnowSomeone.tsx" />
            <Text.P muted strong small className="text-purple-3" data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">
              Vouch from Amy
            </Text.P>
          </div>
          <Text.P strong className="text-purple" small data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">
            &ldquo;Jackie is a creative powerhouse, blending deep media expertise with a knack for
            storytelling that hits home. Cool, capable, and visionary, Jackie is a game-changer in
            the creative world. &rdquo;
          </Text.P>
          <div className="grid grid-cols-2">
            <div>
              <Text.P small muted className="text-purple-3" data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">
                Position
              </Text.P>
              <Text.P small muted className="text-purple-3" data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">
                Creative Director
              </Text.P>
            </div>
            <div>
              <Text.P small muted className="text-purple-3" data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">
                Reward
              </Text.P>
              <Text.P small muted className="text-purple-3" data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">
                $10000
              </Text.P>
            </div>
          </div>
        </div>
        <div className="z-10 flex flex-col justify-end gap-8 lg:items-end lg:self-end">
          <Text.H3 className="max-w-xs text-yellow-2 lg:text-end" data-sentry-element="unknown" data-sentry-source-file="KnowSomeone.tsx">Make them shine!</Text.H3>
          <MakeThemShine data-sentry-element="MakeThemShine" data-sentry-source-file="KnowSomeone.tsx" />
        </div>
      </div>
    </div>;
};