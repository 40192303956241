"use client";

import Link from "next/link";
import { Button } from "@/components/ui/Button";
import { useLocaleProvider } from "../../LocaleProvider";
import { useIsClient } from "@/hooks/useIsClient";
export const MakeThemShine = () => {
  const isClient = useIsClient();
  const {
    country
  } = useLocaleProvider();
  if (country !== "NO" || !isClient) {
    return null;
  }
  return <Link href="/discovery" className="block" data-sentry-element="Link" data-sentry-component="MakeThemShine" data-sentry-source-file="MakeThemShine.tsx">
      <Button rounded variant="yellow" size="lg" data-sentry-element="Button" data-sentry-source-file="MakeThemShine.tsx">
        Vouch for someone
      </Button>
    </Link>;
};